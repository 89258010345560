import React, {useState} from 'react';
import {checkFileErr, formatDateFunction, getCurrencyString, getPermissionSites, parseNum} from 'app/helpers';
import {Button, Checkbox, Col, Form, Input, message, Radio, Row, Select, Space, Spin} from 'antd';
import type Types from 'MyTypes';
import {create, reset} from './actions';
import {useDispatch, useSelector} from 'react-redux';
import {type UploadFile} from 'app/models/upload-file';
import {type FilterOptionItem} from 'app/models/ui-filter';
import {get as getWorkOrders} from '../WorkOrder/actions';
import {
	billStatusLabels,
	billTypeLabels,
	ModuleName,
	ModulePermission,
	OrderType,
	type PaymentMilestone,
	type Site,
	type WorkOrderBill,
	WorkOrderBillStatus,
	WorkOrderBillType,
	type WorkOrderDebit,
	WorkOrderStatus,
} from 'app/models';
import {FileUpload} from '../Common/FileUpload';
import {get as getMasters} from '../ProjectProcessMaster/actions';
import {ProjectProcessType} from 'app/components/ProjectProcessMaster/project-process-master';
import {getContractors} from '../Common/summary-actions';
import {SiteSelect} from '../Common/SiteSelect';
import {SafeSubmitButton} from '../Common/SafeSubmitButton';

// eslint-disable-next-line complexity
export const ViewWorkOrderBillForm: React.FC = () => {
	const dispatch = useDispatch();
	const {loading, dataUpdated, errorMessage} = useSelector((state: Types.RootState) => state.workOrderBill);
	const {byIds: byIdsWo, allIds: allIdsWo} = useSelector((state: Types.RootState) => state.workOrder);
	const {byIds: masterByIds, allIds: masterAllIds} = useSelector((state: Types.RootState) => state.projectProcessMaster);
	const {contractorOptions} = useSelector((state: Types.RootState) => state.summary);
	const {byModule: permissions, allSites} = useSelector((state: Types.RootState) => state.userPermission);

	const sites: Site[] = getPermissionSites(permissions, ModuleName.WORK_ORDER_BILL, ModulePermission.WRITE, allSites);

	const [billDetail, setBillDetail] = useState<Omit<WorkOrderBill, 'id'>>({
		orderBillStatus: WorkOrderBillStatus.PENDING,
		siteId: 0,
		workOrderId: 0,
		projectProcessMasterId: 0,
		contractorId: 0,
		workOrderBillType: undefined,
		billAmount: 0,
		billDescription: '',
		isAdvanceAmount: false,
		paymentMilestones: [],
		advancePaymentMilestones: [],
		debits: [],
		qualityCheck: false,
		workAsPerSchedule: false,
		// eslint-disable-next-line @typescript-eslint/naming-convention
		contractorVisitAsPerWO: false,
		// eslint-disable-next-line @typescript-eslint/naming-convention
		foremenAsPerWO: false,
		penalties: [],
		quantityMeasuredOnSite: '',
		attachments: '',
		debitedToContractor: undefined,
		notDebitedToContractorReason: '',
		contractorInformed: false,
		contractorInformedFiles: '',
		contractorNotInformedReason: '',
		debitWorkOrderId: 0,
	});

	const [plasterDebitAmount, setPlasterDebitAmount] = useState<number>(0);
	const [penaltyAmount, setPenaltyAmount] = useState<number>(0);
	const [penaltyReason, setPenaltyReason] = useState<string>('');
	const [fileList, setFileList] = useState<UploadFile[]>([]);
	const [filePrefixKeys, setFilePrefixKeys] = useState<Record<string, string>>({});
	const [informedFileList, setInformedFileList] = useState<UploadFile[]>([]);
	const [informedFilePrefixKeys, setInformedFilePrefixKeys] = useState<Record<string, string>>({});

	React.useEffect(() => {
		dispatch(reset());
		const siteIds = sites.map(({id}: Site) => id);
		dispatch(getWorkOrders({
			where: {siteId: {inq: siteIds}, status: {inq: [WorkOrderStatus.PUBLISHED, WorkOrderStatus.TERMINATED]}},
			include: [
				{relation: 'paymentMilestones'},
				{relation: 'debits'},
			],
		}));
		dispatch(getContractors());
		dispatch(getMasters({where: {projectProcessType: ProjectProcessType.ORDER}}));
	}, []);

	React.useEffect(() => {
		if (dataUpdated) {
			void message.success('Created successfully');
			setTimeout(() => {
				window.location.reload();
			}, 1000);
		}
	}, [dataUpdated]);

	React.useEffect(() => {
		if (errorMessage) {
			void message.error(errorMessage);
		}
	}, [errorMessage]);

	const addPaymentMilestone = (paymentMilestone: PaymentMilestone, isAdvanceAmount: boolean) => {
		if (isAdvanceAmount) {
			const items = [...billDetail.advancePaymentMilestones];
			items.push(paymentMilestone);
			setBillDetail({...billDetail, advancePaymentMilestones: items});
		} else {
			const items = [...billDetail.paymentMilestones];
			items.push(paymentMilestone);
			setBillDetail({...billDetail, paymentMilestones: items});
		}
	};

	const removePaymentMilestone = (paymentMilestone: PaymentMilestone, isAdvanceAmount: boolean) => {
		if (isAdvanceAmount) {
			const items = [...billDetail.advancePaymentMilestones];
			const i = items.findIndex(m => m.id === paymentMilestone.id);
			items.splice(i, 1);
			setBillDetail({...billDetail, advancePaymentMilestones: items});
		} else {
			const items = [...billDetail.paymentMilestones];
			const i = items.findIndex(m => m.id === paymentMilestone.id);
			items.splice(i, 1);
			setBillDetail({...billDetail, paymentMilestones: items});
		}
	};

	const addDebit = (debit: WorkOrderDebit) => {
		const items = billDetail.debits ? [...billDetail.debits] : [];
		items.push(debit);
		setBillDetail({...billDetail, debits: items});
	};

	const removeDebit = (i: number) => {
		const items = billDetail.debits ? [...billDetail.debits] : [];
		items.splice(i, 1);
		setBillDetail({...billDetail, debits: items});
	};

	const getPenaltyAmount = () => {
		const total = billDetail.debits?.reduce((amt: number, debit: WorkOrderDebit) => {
			amt += debit.amount;
			return amt;
		}, 0);
		return parseNum(total) + parseNum(penaltyAmount) + parseNum(plasterDebitAmount);
	};

	const getBillAmountView = () => {
		if (!billDetail.billAmount) {
			return 'Bill of 0';
		}

		const penaltyAmount = getPenaltyAmount();
		if (penaltyAmount && billDetail.billAmount) {
			return (
				<span>
					Bill of
					{' '}
					<s>{getCurrencyString(billDetail.billAmount)}</s>
					{' '}
					{getCurrencyString(billDetail.billAmount - penaltyAmount)}
				</span>
			);
		}

		return `Bill of ${getCurrencyString(billDetail.billAmount)}`;
	};

	const workOrderById = (id?: number) => id ? byIdsWo[id] : undefined;

	// eslint-disable-next-line complexity
	const onSubmitClicked = () => {
		console.log('submitted');
		const data: Omit<WorkOrderBill, 'id'> = {...billDetail};

		if (!data.siteId) {
			void message.error('Please Select a Site');
			return;
		}

		if (!data.workOrderBillType) {
			void message.error('Please Select a Contractor Bill');
			return;
		}

		if (data.workOrderBillType === WorkOrderBillType.WORK_ORDER && !data.workOrderId) {
			void message.error('Please Select a Work Order');
			return;
		}

		if (!fileList?.length) {
			void message.error('No invoices/attachments uploaded');
			return;
		}

		if (!data.billAmount) {
			void message.error('Please enter bill amount');
			return;
		}

		if (data.workOrderId) {
			if (!data.isAdvanceAmount && !data.paymentMilestones.length) {
				void message.error('No payment milestone selected');
				return;
			}
		} else if (!data.billDescription) {
			void message.error('Please enter bill description');
			return;
		}

		if (data.isAdvanceAmount && !data.billDescription) {
			void message.error('Please enter advance amount description');
			return;
		}

		if (data.workOrderId && !data.isAdvanceAmount && data.paymentMilestones.length) {
			const totalMilestone = data.paymentMilestones.reduce((prevVal, val) => prevVal + val.amount, 0);
			if (parseInt(String(data.billAmount), 10) !== totalMilestone) {
				void message.error('Bill amount does not match with all milestones amount');
				return;
			}
		}

		if (data.workOrderBillType === WorkOrderBillType.EXTRA_AMOUNT || data.workOrderBillType === WorkOrderBillType.WARRANTY_BILL) {
			if (data.debitedToContractor === undefined) {
				void message.error('Please select if the bill amount will be debited to any contractor or not!');
				return;
			}

			if (!data.debitedToContractor && !data.notDebitedToContractorReason) {
				void message.error('Please enter a reason for not debiting to contractor!');
				return;
			}
		}

		if (data.debitedToContractor) {
			if (!data.debitWorkOrderId) {
				void message.error('Please select a work order to debit from!');
				return;
			}

			if (data.contractorInformed === undefined) {
				void message.error('Please select if the contractor has been informed for this debit!');
				return;
			}

			if (data.contractorInformed && (!informedFileList?.length)) {
				void message.error('Please upload screenshots of the communication!');
				return;
			}

			if (!data.contractorInformed && !data.contractorNotInformedReason) {
				void message.error('Please provide a reason for no communication!');
				return;
			}
		}

		if (fileList) {
			checkFileErr(fileList);
			data.attachments = fileList
				.map(file => `${filePrefixKeys ? filePrefixKeys[file.uid] : ''}/${file.name}`).join(',');
		}

		if (informedFilePrefixKeys && informedFileList) {
			checkFileErr(informedFileList);
			data.contractorInformedFiles = informedFileList
				.map((file: UploadFile) => `${informedFilePrefixKeys ? informedFilePrefixKeys[file.uid] : ''}/${file.name}`).join(',');
		}

		data.penalties = [];
		if (penaltyAmount) {
			if (penaltyAmount < 0) {
				void message.error('Negative penalty not allowed');
				return;
			}

			data.penalties.push({id: 0, amount: penaltyAmount, description: penaltyReason});
		}

		if (plasterDebitAmount) {
			if (plasterDebitAmount < 0) {
				void message.error('Negative plaster debit not allowed');
				return;
			}

			data.penalties.push({id: 0, amount: plasterDebitAmount, description: 'Plaster Debit Amount'});
		}

		console.log({data});

		dispatch(create(data));
	};

	const masterOptions = masterAllIds.map(id => ({value: id.toString(), label: masterByIds[id]?.processName}));
	const siteOptions: FilterOptionItem[] = sites
		.map(({id, name}: Site) => ({value: id.toString(), label: name}));

	const orderOptions = allIdsWo
		.filter((id: number) => (!billDetail.siteId || byIdsWo[id].siteId === billDetail.siteId) && (!billDetail.projectProcessMasterId || byIdsWo[id].projectProcessMasterId === billDetail.projectProcessMasterId))
		.map((id: number) => ({value: id.toString(), label: byIdsWo[id].status === WorkOrderStatus.TERMINATED ? byIdsWo[id].title + ' (Terminated)' : byIdsWo[id].title}));

	const milestoneOptions = billDetail.workOrderId && byIdsWo[billDetail.workOrderId]?.paymentMilestones?.length
		? byIdsWo[billDetail.workOrderId].paymentMilestones.filter(m => !m.isRemovalPending).sort((m1, m2) => (m1.sequence ?? 0) >= (m2.sequence ?? 0) ? 0 : -1) : [];

	const debitOptions = billDetail.workOrderId && byIdsWo[billDetail.workOrderId]
		? byIdsWo[billDetail.workOrderId].debits : [];

	const layout = {
		labelCol: {span: 8},
		wrapperCol: {span: 16},
	};
	const tailLayout = {
		wrapperCol: {offset: 8, span: 16},
	};

	return (
		<Spin
			size='large'
			spinning={loading}
			tip={'Loading...'}
		>
			<Row className='mb-15'>
				<Col span={24}>
					<Form
						{...layout}
						name='basic'
					>
						<Form.Item label='Site'>
							<SiteSelect
								siteOptions={siteOptions}
								noneOption={true}
								style={{width: '100%'}}
								placeholder='Select a Site'
								onChange={value => {
									setBillDetail({...billDetail, siteId: parseNum(value as string)});
								}}
							/>
						</Form.Item>

						<Form.Item label='Contractor Bill Type'>
							<Select
								style={{width: '100%'}}
								placeholder='Select Contractor Bill Type'
								onChange={(billType: WorkOrderBillType) => {
									setBillDetail({
										...billDetail,
										...{
											workOrderBillType: billType,
											workOrderId: 0,
											projectProcessMasterId: undefined,
											billDescription: '',
											paymentMilestones: [],
											advancePaymentMilestones: [],
											debits: [],
											isAdvanceAmount: false,
											debitedToContractor: Boolean(billType === WorkOrderBillType.DEBIT_TO_CONTRACTOR || billType === WorkOrderBillType.CHALLAN_BILL_CONTRACTOR_DEBIT) || undefined,
											debitWorkOrderId: undefined,
											notDebitedToContractorReason: undefined,
											contractorInformed: undefined,
											contractorInformedFiles: undefined,
											contractorNotInformedReason: undefined,
										},
									});
									setInformedFileList([]);
									setInformedFilePrefixKeys({});
								}}
							>
								<Select.Option key={1} value={WorkOrderBillType.WORK_ORDER}>{billTypeLabels[WorkOrderBillType.WORK_ORDER]}</Select.Option>
								<Select.Option key={2} value={WorkOrderBillType.MALWA_BILL}>{billTypeLabels[WorkOrderBillType.MALWA_BILL]}</Select.Option>
								<Select.Option key={3} value={WorkOrderBillType.SCAFFOLDING_BILL}>{billTypeLabels[WorkOrderBillType.SCAFFOLDING_BILL]}</Select.Option>
								<Select.Option key={3} value={WorkOrderBillType.WARRANTY_BILL}>{billTypeLabels[WorkOrderBillType.WARRANTY_BILL]}</Select.Option>
								<Select.Option key={4} value={WorkOrderBillType.EXTRA_AMOUNT}>{billTypeLabels[WorkOrderBillType.EXTRA_AMOUNT]}</Select.Option>
								<Select.Option key={4} value={WorkOrderBillType.DEBIT_TO_CONTRACTOR}>{billTypeLabels[WorkOrderBillType.DEBIT_TO_CONTRACTOR]}</Select.Option>
								<Select.Option key={4} value={WorkOrderBillType.CHALLAN_BILL_CONTRACTOR_DEBIT}>{billTypeLabels[WorkOrderBillType.CHALLAN_BILL_CONTRACTOR_DEBIT]}</Select.Option>
							</Select>
						</Form.Item>

						{billDetail.workOrderBillType === WorkOrderBillType.MALWA_BILL || billDetail.workOrderBillType === WorkOrderBillType.SCAFFOLDING_BILL ? (
							<Form.Item label='Contractor'>
								<Select
									showSearch={true}
									style={{width: 250}}
									placeholder='Select a Contractor'
									optionFilterProp='children'
									onChange={value => {
										setBillDetail({...billDetail, contractorId: parseNum(value as string)});
									}}
								>
									{contractorOptions?.map((option: FilterOptionItem, ix: number) =>
										<Select.Option key={ix} value={option.value}>{option.label}</Select.Option>,
									)}
									<Select.Option key={-1} value={'0'}>Contractor Not Listed</Select.Option>
								</Select>
							</Form.Item>
						) : []}

						{billDetail.workOrderBillType === WorkOrderBillType.EXTRA_AMOUNT || billDetail.workOrderBillType === WorkOrderBillType.WARRANTY_BILL ? (
							<Form.Item label='Debit this Bill to a Contractor?'>
								<Radio.Group
									onChange={e => {
										setBillDetail({
											...billDetail,
											...{
												debitedToContractor: Boolean(e.target.value),
												notDebitedToContractorReason: undefined,
												contractorInformed: undefined,
												contractorInformedFiles: undefined,
												contractorNotInformedReason: undefined,
											},
										});
										setInformedFileList([]);
										setInformedFilePrefixKeys({});
									}}
								>
									<Radio value={true}>Yes</Radio>
									<Radio value={false}>No</Radio>
								</Radio.Group>
							</Form.Item>
						) : []}

						{billDetail.debitedToContractor === undefined ? [] : (billDetail.debitedToContractor ? (
							<>
								<Form.Item label={'Has Contractor been Informed of this Debit?'}>
									<Radio.Group
										onChange={e => {
											setBillDetail({
												...billDetail,
												...{
													contractorInformed: Boolean(e.target.value),
													contractorInformedFiles: undefined,
													contractorNotInformedReason: undefined,
												},
											});
											setInformedFileList([]);
											setInformedFilePrefixKeys({});
										}}
									>
										<Radio value={true}>Yes</Radio>
										<Radio value={false}>No</Radio>
									</Radio.Group>
								</Form.Item>
								{billDetail.contractorInformed === undefined ? [] : (
									billDetail.contractorInformed ? (
										<Form.Item
											label='Upload screenshots of the communication'
											extra={'Upload Whatsapp message screenshot or email screenshot of the conversation with contractor'}
										>
											<FileUpload
												prefix={'labour-request'}
												fileList={informedFileList}
												filePrefixKeys={informedFilePrefixKeys}
												onFileChange={(list, prefixKeys) => {
													setInformedFileList(list);
													setInformedFilePrefixKeys(prefixKeys);
												}}
											/>
										</Form.Item>
									) : (
										<Form.Item label={(<span>Please Enter a Reason for not Informing<br /> Contractor of this Debit</span>)}>
											<Input.TextArea
												placeholder={'Enter a Reason'}
												value={billDetail.contractorNotInformedReason ?? ''}
												onChange={(e: any) => {
													setBillDetail({...billDetail, contractorNotInformedReason: String(e.target.value)});
												}}
											/>
										</Form.Item>
									)
								)}
								<Form.Item label='Select Work Order of the Contractor'>
									<Select
										showSearch={true}
										style={{width: 250}}
										placeholder='Select a Work Order'
										optionFilterProp='children'
										onChange={(value: string) => {
											setBillDetail({...billDetail, debitWorkOrderId: parseNum(value)});
										}}
									>
										{orderOptions.map((option: FilterOptionItem, ix: number) =>
											<Select.Option key={ix} value={option.value}>{option.label}</Select.Option>,
										)}
									</Select>
								</Form.Item>
							</>
						) : (
							<Form.Item label={(<span>Please Enter a Reason for not Debiting<br /> this Bill to a Contractor</span>)}>
								<Input.TextArea
									placeholder={'Enter a Reason'}
									value={billDetail.notDebitedToContractorReason ?? ''}
									onChange={(e: any) => {
										setBillDetail({...billDetail, notDebitedToContractorReason: String(e.target.value)});
									}}
								/>
							</Form.Item>
						))}

						{billDetail.workOrderBillType && billDetail.workOrderBillType === WorkOrderBillType.WORK_ORDER ? (
							<Form.Item label='Please Select Work Order'>
								<Select
									showSearch={true}
									style={{width: '100%'}}
									placeholder='Select a work order'
									optionFilterProp='children'
									onChange={value => {
										setBillDetail({
											...billDetail,
											...{
												workOrderId: parseNum(value as string),
												projectProcessMasterId: undefined,
												paymentMilestones: [],
												advancePaymentMilestones: [],
												debits: [],
												isAdvanceAmount: false,
												debitedToContractor: undefined,
												debitWorkOrderId: undefined,
												notDebitedToContractorReason: undefined,
												contractorInformed: undefined,
												contractorInformedFiles: undefined,
												contractorNotInformedReason: undefined,
											},
										});
										setInformedFileList([]);
										setInformedFilePrefixKeys({});
									}}
								>
									{orderOptions.map((option: FilterOptionItem, ix: number) =>
										<Select.Option key={ix} value={option.value}>{option.label}</Select.Option>,
									)}
								</Select>
							</Form.Item>
						) : []}

						{billDetail.workOrderBillType && (
							billDetail.workOrderBillType === WorkOrderBillType.EXTRA_AMOUNT
							|| billDetail.workOrderBillType === WorkOrderBillType.WARRANTY_BILL
							|| billDetail.workOrderBillType === WorkOrderBillType.DEBIT_TO_CONTRACTOR
						) ? (
								<Form.Item label='Please Select Work Type'>
									<Select
										showSearch={true}
										style={{width: '100%'}}
										placeholder='Select a work type'
										optionFilterProp='children'
										onChange={value => {
											setBillDetail({...billDetail, projectProcessMasterId: parseNum(value as string)});
										}}
									>
										{masterOptions.map((option: FilterOptionItem, ix: number) =>
											<Select.Option key={ix} value={option.value}>{option.label}</Select.Option>,
										)}
									</Select>
								</Form.Item>
							) : []}

						{billDetail.workOrderId ? (
							<Form.Item label='Checks'>
								<Space direction={'vertical'}>
									<Checkbox onChange={e => {
										setBillDetail({...billDetail, qualityCheck: e.target.checked});
									}}>
										{`Is quality checked for the work order "${workOrderById(billDetail.workOrderId)?.title}`}
									</Checkbox>
									<Checkbox onChange={e => {
										setBillDetail({...billDetail, workAsPerSchedule: e.target.checked});
									}}>
										Is work as per the schedule
									</Checkbox>
									<Checkbox onChange={e => {
										// eslint-disable-next-line @typescript-eslint/naming-convention
										setBillDetail({...billDetail, contractorVisitAsPerWO: e.target.checked});
									}}>
										Did contractor visit the site {workOrderById(billDetail.workOrderId)?.contractorVisit}
									</Checkbox>
									<Checkbox onChange={e => {
										// eslint-disable-next-line @typescript-eslint/naming-convention
										setBillDetail({...billDetail, foremenAsPerWO: e.target.checked});
									}}>
										Is his foreman/ site incharge of site as per work order
									</Checkbox>
								</Space>
							</Form.Item>
						) : []}

						<Form.Item label='Invoices/Attachments'>
							<FileUpload
								label={'Click to Upload Invoices/Attachments'}
								prefix={'work-order-bill'}
								fileList={fileList ?? []}
								filePrefixKeys={filePrefixKeys ?? {}}
								onFileChange={(list, prefixKeys) => {
									setFileList(list);
									setFilePrefixKeys(prefixKeys);
								}}
							/>
						</Form.Item>

						<Form.Item label='Bill Amount'>
							<Input
								type={'number'}
								defaultValue={billDetail.billAmount}
								value={billDetail.billAmount}
								onChange={(e: any) => {
									setBillDetail({...billDetail, billAmount: parseNum(String(e.target.value))});
								}}
							/>
						</Form.Item>

						{billDetail.workOrderBillType && billDetail.workOrderBillType !== WorkOrderBillType.WORK_ORDER ? (
							<Form.Item label='Bill Description'>
								<Input.TextArea
									placeholder={'Enter Bill Description'}
									defaultValue={billDetail.billDescription ?? ''}
									value={billDetail.billDescription ?? ''}
									onChange={(e: any) => {
										setBillDetail({...billDetail, billDescription: String(e.target.value)});
									}}
								/>
							</Form.Item>
						) : []}

						{billDetail.workOrderBillType && billDetail.workOrderBillType === WorkOrderBillType.WORK_ORDER ? (
							<Form.Item label='Is this an Advance Amount'>
								<Radio.Group
									onChange={e => {
										setBillDetail({
											...billDetail,
											...{
												billDescription: '',
												isAdvanceAmount: Boolean(e.target.value),
												paymentMilestones: [],
												advancePaymentMilestones: [],
												debits: [],
												debitedToContractor: undefined,
												debitWorkOrderId: undefined,
												notDebitedToContractorReason: undefined,
												contractorInformed: undefined,
												contractorInformedFiles: undefined,
												contractorNotInformedReason: undefined,
											},
										});
										setInformedFileList([]);
										setInformedFilePrefixKeys({});
									}}
								>
									<Radio value={true}>Yes</Radio>
									<Radio value={false}>No</Radio>
								</Radio.Group>
							</Form.Item>
						) : []}

						{billDetail.isAdvanceAmount ? (
							<Form.Item label='Advance Amount Description'>
								<Input.TextArea
									placeholder={'Enter Description'}
									defaultValue={billDetail.billDescription ?? ''}
									value={billDetail.billDescription ?? ''}
									onChange={(e: any) => {
										setBillDetail({...billDetail, billDescription: String(e.target.value)});
									}}
								/>
							</Form.Item>
						) : []}

						{milestoneOptions && milestoneOptions.length > 0 ? (
							<Form.Item label={billDetail.isAdvanceAmount ? 'Advance Payment Milestones' : 'Payment Milestones'}>
								<Space direction={'vertical'}>
									{billDetail.isAdvanceAmount ? [] : (<p>
										Total Selected Milestone Amount:
										{' '}
										{getCurrencyString(billDetail.paymentMilestones
											.reduce((amt, milestone) => {
												amt += milestone.amount;
												return amt;
											}, 0), false)}
									</p>)}
									{milestoneOptions.map((milestone: PaymentMilestone, i: number) => {
										const isBillRaised = Boolean(milestone.workOrderBillId);
										const isTerminated = workOrderById(billDetail.workOrderId)?.status === WorkOrderStatus.TERMINATED;
										// Disable if isAdvanceAmount is true and advanceWorkOrderBillId is set
										const isAdvanceDisabled = Boolean(billDetail.isAdvanceAmount) && Boolean(milestone.advanceWorkOrderBillId);
										const advanceWorkOrderBillDetails = milestone.advanceWorkOrderBill
											? ` (Advance: ${getCurrencyString(
												milestone.advanceWorkOrderBill.billAmount ?? 0,
												false,
											)} - ${milestone.advanceWorkOrderBill.billDescription} - ${
												billStatusLabels[milestone.advanceWorkOrderBill.orderBillStatus]
											})`
											: '';

										return (
											<Checkbox
												key={milestone.id}
												disabled={isBillRaised || isAdvanceDisabled || (isTerminated && milestone.sequence !== 0)}
												onChange={e => {
													if (e.target.checked) {
														addPaymentMilestone(milestone, Boolean(billDetail.isAdvanceAmount));
													} else {
														removePaymentMilestone(milestone, Boolean(billDetail.isAdvanceAmount));
													}
												}}
											>
												{isBillRaised
													? '(Bill Raised) '
													: isTerminated && milestone.sequence !== 0
														? '(Bill Cancelled) '
														: ''}
												{`${milestone.name}: ${getCurrencyString(milestone.amount, false)}${
													advanceWorkOrderBillDetails
												}`}
											</Checkbox>
										);
									})}
								</Space>
							</Form.Item>
						) : []}

						{!billDetail.isAdvanceAmount && debitOptions && debitOptions.length > 0 ? (
							<Form.Item label='Debits'>
								<Space direction={'vertical'}>
									<p>
										Total Debited Amount:
										{' '}
										{getCurrencyString(billDetail.debits?.reduce((amt, debit) => {
											amt += debit.amount;
											return amt;
										}, 0), false)}
									</p>
									{debitOptions.map((debit: WorkOrderDebit, i: number) => (
										<Checkbox
											key={debit.id}
											disabled={Boolean(debit.workOrderBillId) || (Boolean(debit.validFrom) && new Date(String(debit.validFrom)) >= new Date())}
											onChange={e => {
												if (e.target.checked) {
													addDebit(debit);
												} else {
													removeDebit(i);
												}
											}}
										>
											{debit.workOrderBillId ? (
												<s>{`(Recovered in Bill) ${debit.description}: ${getCurrencyString(debit.amount, false)}`}</s>
											) : `${debit.validFrom && new Date(debit.validFrom) >= new Date() ? `(Valid from ${formatDateFunction(debit.validFrom, false)})` : ''}
														${debit.description}: ${getCurrencyString(debit.amount, false)}`}
										</Checkbox>
									))}
								</Space>
							</Form.Item>
						) : []}

						{billDetail.workOrderId && byIdsWo[billDetail.workOrderId].orderType === OrderType.RATE ? (
							<Form.Item label='Quantity Measure on Site'>
								<Input
									placeholder={'Enter Quantity Measured on Site'}
									defaultValue={billDetail.quantityMeasuredOnSite ?? ''}
									value={billDetail.quantityMeasuredOnSite ?? ''}
									onChange={(e: any) => {
										setBillDetail({...billDetail, quantityMeasuredOnSite: String(e.target.value)});
									}}
								/>
							</Form.Item>
						) : []}

						{billDetail.isAdvanceAmount ? [] : (
							<>
								<Form.Item label='Plaster Debit Amount (if any)'>
									<Input
										type={'number'}
										defaultValue={plasterDebitAmount}
										value={plasterDebitAmount}
										onChange={(e: any) => {
											setPlasterDebitAmount(parseNum(String(e.target.value)));
										}}
									/>
								</Form.Item>
							</>
						)}

						{billDetail.isAdvanceAmount ? [] : (
							<>
								<Form.Item label='Penalty/Recovery Amount (if any)'>
									<Input
										type={'number'}
										defaultValue={penaltyAmount}
										value={penaltyAmount}
										onChange={(e: any) => {
											setPenaltyAmount(parseNum(String(e.target.value)));
										}}
									/>
								</Form.Item>
								<Form.Item label='Penalty/Recovery Amount Reason (if any)'>
									<Input.TextArea
										placeholder={'Enter Reason'}
										defaultValue={penaltyReason ?? ''}
										value={penaltyReason ?? ''}
										onChange={(e: any) => {
											setPenaltyReason(String(e.target.value));
										}}
									/>
								</Form.Item>
							</>
						)}

						<Form.Item {...tailLayout}>
							<SafeSubmitButton
								type='primary'
								onClick={onSubmitClicked}
								disabled={!billDetail.billAmount}
							>
								Submit {getBillAmountView()}
							</SafeSubmitButton>
						</Form.Item>
					</Form>
				</Col>
			</Row>
		</Spin>
	);
};
